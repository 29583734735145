export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    formLabel: '所属加油站',
    key: 'siteName',
    align: 'left',
    minWidth: 100
  },
  {
    title: '液位仪编号',
    key: 'num',
    minWidth: 100
  },
  {
    title: '油罐数量',
    key: 'tankNum',
    type: 'number'
  },
  // {
  //   title: '液位仪型号',
  //   key: 'type',
  //   minWidth: 100
  // },
  {
    title: '出厂日期',
    key: 'startDate',
    width: 120
  },
  {
    title: '液位仪状态',
    key: 'status',
    width: 100,
    hideInForm: true,
    scopedSlots: {
      customRender: 'status'
    }
  },
  {
    title: '操作',
    fixed: 'right',
    key: 'actions',
    width: 170,
    scopedSlots: {
      customRender: 'actions'
    }
  }
];

export const formDesc = {
  // status: {
  //   type: 'select',
  //   layout: 12,
  //   label: '状态',
  //   options: [
  //     { text: '在线', value: 1 },
  //     { text: '离线', value: 2 }
  //   ]
  // },
  // type: {
  //   type: 'input',
  //   layout: 12,
  //   label: '液位仪型号'
  // },
  startDate: {
    type: 'date',
    layout: 12,
    label: '出厂日期',
    attrs: {
      'value-format': 'yyyy-MM-dd'
    }
  },
  contain: {
    type: 'switch',
    layout: 12,
    label: '是否连接双层罐泄露监测装置',
    attrs: {
      activeText: '是',
      inactiveText: '否'
    }
  }
};
