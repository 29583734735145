<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <div style="width: 100%; display: flex; justify-content: space-between;">
        <div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="
              visible = true;
              dialogAdd = true;
            "
            class="export-btn"
            >新增</el-button
          >
        </div>
        <el-form :inline="true" size="mini">
          <AreaField
            v-model="searchParams.area_code"
            @change="getStationList"
          />
          <el-form-item>
            <el-select
              v-model="searchParams.siteId"
              filterable
              :clearable="true"
              placeholder="加油站(可输入)"
            >
              <el-option
                v-for="item in gasData"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
              >查询</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-arrow-up"
              size="mini"
              @click="handleExport"
              class="export-btn"
              >导出</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </template>

    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #status="{ scope }">
        <span :class="scope.row.status === 1 ? 'text-info' : 'text-danger'">{{
          scope.row.status === 1 ? '在线' : '离线'
        }}</span>
      </template>
      <template #actions="{ scope }">
        <el-button type="primary" size="small" @click="handleGetRow(scope.row)"
          >编辑</el-button
        >
        <el-button type="danger" size="small" @click="handleDel(scope.row.id)"
          >删除</el-button
        >
      </template>
    </default-table>

    <el-dialog
      :title="dialogAdd ? '新增' : '编辑'"
      :visible.sync="visible"
      width="900px"
      custom-class="dialog"
      :close-on-click-modal="false"
    >
      <ele-form
        v-model="RowInfo"
        :form-desc="formDesc"
        ref="form"
        :rules="rules"
        labelPosition="right"
        :isShowSubmitBtn="true"
        :isShowCancelBtn="true"
        :isShowBackBtn="false"
        :span="24"
        :request-fn="handleSubmit"
        :isShowErrorNotify="false"
        @cancel="handleCancel"
      >
      </ele-form>
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import {
  QueryLevelGauge,
  ExportLevelGauge,
  LevelGaugeAdd,
  LevelGaugeEdit,
  LevelGaugeDel
} from '@/api/level_gauge_info';

import DefaultTable from '@/components/DefaultTable/new';
import { columns, formDesc } from './columns/LevelGauge';
import { getGasData } from '@/api/global';
import mixins from '@/mixins';

export default {
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    return {
      tableLoading: true,
      dialogAdd: true, // true：新增/false：修改
      searchParams: {},
      columns,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      rules: {
        num: [{ required: true, message: '请填写液位仪编号' }],
        siteId: [{ required: true, message: '请选择加油站' }],
        status: [{ required: true, message: '请设置状态' }],
        // type: [{ required: true, message: '请填写液位仪型号' }],
        startDate: [{ required: true, message: '请填写生产日期' }]
      },
      formDesc: {
        num: {
          type: 'input',
          layout: 12,
          label: '液位仪编号',
          rule: [{}]
        },
        siteId: {
          type: 'select',
          layout: 12,
          label: '所属加油站',
          prop: {
            text: 'name',
            value: 'id'
          },
          options: async () => {
            const result = await getGasData();
            if (result && result.code === 0) {
              return result.data;
            } else {
              return [];
            }
          },
          attrs: {
            filterable: true
          }
        },
        ...formDesc
      },
      visible: false,
      RowInfo: {}
    };
  },
  mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.tableLoading = true;
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      QueryLevelGauge(params).then(res => {
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(current) {
      this.pagination.current = current;
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        site_id: this.searchParams.siteId,
        factory: this.searchParams.manufactor
      };

      ExportLevelGauge(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    async handleSubmit(fields) {
      let params = {
        ...fields
      };
      params.contain = params.contain ? 1 : 2;
      let result;
      if (this.dialogAdd) {
        result = await LevelGaugeAdd(params);
      } else {
        result = await LevelGaugeEdit(params);
      }
      if (result && result.code === 0) {
        this.$message.success(result.msg);
        this.handleCancel();
        return result;
      } else {
        this.$message.error(result.msg);
      }
    },
    handleDel(id) {
      this.$confirm('是否删除该液位仪？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          LevelGaugeDel({ id }).then(res => {
            console.log(res);
            if (res.code === 0) {
              this.$message.success(res.msg);
              this.handleQuery({
                current: this.pagination.current,
                pageSize: this.pagination.pageSize
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    handleCancel() {
      this.visible = false;
      this.dialogAdd = false;
      this.RowInfo = {};
      this.$refs['form'].resetForm();
      this.handleQuery({
        current: this.pagination.current,
        pageSize: this.pagination.pageSize
      });
    },
    handleGetRow(data) {
      this.visible = !this.visible;
      this.dialogAdd = false;
      this.RowInfo = data;
      this.RowInfo.contain = this.RowInfo.contain === 1 ? true : false;
      console.log(this.RowInfo);
    }
  }
};
</script>

<style lang="scss" scoped>
.text-danger {
  color: #f56c6c;
}

.text-info {
  color: #01adbe;
}
</style>
